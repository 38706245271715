.ServicioCard {
    background: #FFFFFF;
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 350px;
    margin: 0px 10px 10px 10px;
}

.servicio-title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 19px;
    color: #30C6F5;
    /* width: auto; */
}

.servicio-subtitle {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #30C6F5;
    /* width: auto; */
}

.servicio-title2 {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 19px;
    color: #5797FE;
}

.servicio-subtitle2 {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #5797FE;
}

.servicios-display {
    display: flex;
    justify-items: start;
    flex-direction: row;
}

@media (max-width:798px){
    .ServicioCard {
        box-shadow: 1px 1px 8px 2px #dbe9fe;
        border: 1px #dbe9fe;
        max-width: 75vw;
    }
    .servicios-display {
        flex-direction: column;
    }
}