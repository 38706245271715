.font-footer {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #666680;
}

.subtitle-footer-font {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0060FF;
}

.font-logo-footer {
    font-style: italic;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #0060FF;
}

.social-logo {
    border: 1px solid #E8E8F6;
    border-radius: 50px;
    padding: 8px 13px;
    margin: 0em 0.1em;
    display: inline-block;
}

.social-logo-f {
    border: 1px solid #E8E8F6;
    border-radius: 50px;
    padding: 8px 16px;
    margin: 0em 0.1em;
    display: inline-block;
}

li {
    cursor: pointer;
}