.card-beneficios-col {
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 100%;
}

.card-beneficios{
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.card-body-beneficios{
    margin: 1em;
}

.card-title-beneficios{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    height: auto;
}

.card-subtitle-beneficios {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #5E6E78;
    height: auto;
}

.card-contenido-beneficios {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #969A9D;
}

@media (max-width:798px){
    .card-beneficios {
        height: auto;
        box-shadow: 1px 1px 8px 2px #dbe9fe;
        border: 1px #dbe9fe;
    }

    .card-beneficios-col {
        box-shadow: 1px 1px 8px 2px #dbe9fe;
        border: 1px #dbe9fe;
        height: auto;
    }

    .card-title-beneficios{
        margin-left: 1.5rem;
    }

    .card-subtitle-beneficios{
        margin-left: 1.6rem;
    }
}