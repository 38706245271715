.about-title {
    font-weight: 600;
    font-size: 48px;
    line-height: 50px;
    color: #000000;
    margin-top: 10vh;
    margin-bottom: 5vh;
}

.content-container{
    margin-bottom: 2vh;
}