.Navbar {
    background: #f7fafe;
}

.font-logo {
    font-style: italic;
    font-weight: 700;
    font-size: 27px;
    text-align: center;
    color: #0060FF;
    cursor: pointer;
}

.navbar-element {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    margin: 0em 0.5em;
    cursor: pointer;
}

.navbar-element-blue {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: #0060FF;
}

.navbar-element-blue:hover {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: #0060FF;
    text-decoration: underline 1px #0060FF;
}

.navbar-element:hover {
    color: #0060FF;
}

.btn-blue {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 17px;
    background: #0060FF;
    color: #ffff;
    width: 8rem;
}

.btn-blue:hover {
    color: #0060FF;
    text-decoration: underline 1px #0060FF;
    border: 1px solid #0060FF;
}

.navbar-toggler-color {
    color: #0060FF !important;
    border: 0 !important;
    background: #f7fafe;
}

.img-navbar {
    object-fit: cover;
}

.brand-container {
    width: 180px;
}

.rb-container {
    min-width: 250px;
    display: flex;
    flex-direction: row;
}

@media (max-width:798px){
    .btn-blue {
    width: 81px;
    height: auto;
    font-size: 12px;
    }

    .font-logo {
        font-size: 18px;
    }

    .img-navbar {
        width: 170px;
        height: auto;
        margin-left: 15%;
    }

    .btn-solicitar-nav {
        margin: auto;
    }
}